//@index('./*.ts', f => `export * from '${f.path}';`)
export * from './api.v2.model';
export * from './bip.v2.model';
export * from './crisis-plan.v2.model';
export * from './doctor.v2.model';
export * from './document.v2.model';
export * from './generalization-training.v2.model';
export * from './goal.v2.model';
export * from './insurance.v2.model';
export * from './location.v2.model';
export * from './note-bcba.v2.model';
export * from './note.rbt.v2.model';
export * from './objective.v2.model';
export * from './pa-service.v2.model';
export * from './patient.v2.model';
export * from './plan.v2.model';
export * from './pos-covered.v2.model';
export * from './provider.v2.model';
