import { Component } from '@angular/core';

@Component({
  selector: 'app-no-info',
  templateUrl: './no-info.component.html',
  styleUrls: ['./no-info.component.scss']
})
export class NoInfoComponent {

}
